<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <span>
        <!-- tmp hide set favorite feature
        <span v-if="projId" class="isLink me-1">
            <span v-if="isFavourite && isFavourite === true">
                <Popper class="popperDark" arrow hover content="Click to remove from favorite">
                    <fa icon="star" size="lg" color="orange" @click.prevent="updFavorite(projId, false)" />
                </Popper>
            </span>
            <span v-else>
                <Popper class="popperDark" arrow hover content="Click to add to favorite">
                    <fa icon="star" size="lg" @click.prevent="updFavorite(projId, true)" class="text-grey" />
                </Popper>
                <fa icon="star" size="lg" class="text-grey" />
            </span>
        </span> -->
        <span v-if="projId" class="me-1">
            <span v-if="isFavourite && isFavourite === true">
                <fa icon="star" size="lg" color="orange" />
            </span>
            <span v-else>
                <fa icon="star" size="lg" class="text-grey" />
            </span>
        </span>

        <ProjStatus :status="status" :showDesc="true" class="me-1" />
      
        <!-- Enerson: temp hide 
        <span v-if="permission !== null" class="isTooltipsLink me-1">
            <span v-if="permission === 'SHARED'" >
                <Popper class="popperDark" arrow hover content="Documents within the same folder are shared among signees">
                    <fa icon="share-square" size="lg" class="text-grey"/>
                </Popper>
            </span>
            <span v-else>
                <Popper class="popperDark" arrow hover content="Documents with permission controlled for every signee">
                    <fa icon="mask" size="lg" class="text-grey"/>
                </Popper>
            </span>
        </span> -->
        <span v-if="isEdit === true || isEdit === 'true'" class="me-1">
            <span v-if="projTitle && projTitle.length > 0" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlEditFolder">
                <Popper class="popperDark" arrow hover content="Edit folder">
                    <span class="fw-bold">{{projTitle}}</span>
                </Popper>
            </span>
            <span v-else class="isLink" data-bs-toggle="modal" data-bs-target="#mdlEditFolder">
                <Popper class="popperDark" arrow hover content="Edit folder">-</Popper>
            </span>
        </span> 
        <span v-else class="fw-bold me-1">
            <span v-if="projTitle && projTitle.length > 0">{{ projTitle }}</span>
            <span v-else>-</span>
        </span> 

        <Popper class="popperDark" arrow hover content="Document">
            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="file" /> {{ totalDoc }}</span>
        </Popper>

        <Popper class="popperDark" arrow hover content="Signee">
            <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="user" /> {{ totalSignee }}</span>
        </Popper>

    </span>

    <!-- Modal --> 
    <div class="modal fade" id="mdlEditFolder" tabindex="-1" aria-labelledby="EditProj" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Edit folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="newProjName" id="folderName" placeholder="Folder Name">
                                <label for="folderName">Folder Name</label>
                            </div>
                            <div class="mt-2 text-end">
                                <button class="btn btn-secondary" @click="updProjName" data-bs-dismiss="modal">
                                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                </button>
                            </div>
                        </div>

                        <div class="col-12"><hr style="color: var(--accent-color)"/></div>

                        <div class="col-12 mt-2 mb-2">Folder Privacy Setting for Public Viewers</div>
                        <div class="col-9 mb-2">
                            <table>
                                <tr>
                                    <td width="30px"><input type="radio" v-model="newProjPrivacy" value="PUBLIC" @change="updProjPrivacy" data-bs-dismiss="modal" /></td>
                                    <td><ProjPrivacy value="PUBLIC" /> Disclose full information</td>
                                </tr>
                                <tr>
                                    <td><input type="radio" v-model="newProjPrivacy" value="PRIVATE" @change="updProjPrivacy" data-bs-dismiss="modal" /></td>
                                    <td><ProjPrivacy value="PRIVATE" /> View name, timestamp and status only</td>
                                </tr>
                                <tr>
                                    <td><input type="radio" v-model="newProjPrivacy" value="CONFIDENTIAL" @change="updProjPrivacy" data-bs-dismiss="modal" /></td>
                                    <td class="pe-1"><ProjPrivacy value="CONFIDENTIAL" /> View document name and timestamp only</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->
</template>

<script>
import {ref, onMounted, inject, computed} from 'vue'
import Popper from 'vue3-popper'
import ProjStatus from '@/components/ProjStatus.vue'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'

export default {
    name: 'ProjTitleSimple',
    components: { Alert, Popper, ProjStatus },
    props: [ 'projId', 'permission', 'projTitle', 'totalSignee', 'totalDoc', 'status', 'isFavourite', 'isEdit'],
    emits: ['refreshProj'],
    setup (props, {emit}) {
        const alert = ref([])
        const axios = inject('axios')
        const func = funcs
        const store = useStore()

        const projId = ref(props.projId)
        const projTitle = ref(props.projTitle)
        const projStatus = ref(props.status)
        const privacy = ref(props.privacy)
        const isFavourite = ref(props.isFavourite)
        const isEdit = ref(props.isEdit)

        const newProjName = ref(props.projTitle)
        const newProjPrivacy = ref('PUBLIC')

        const refreshProj = ref(null)
        const objLSProj = ref({})

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const updFavorite = (id, val) => {
            const p = { isFavourite: val }

            axios.put( '/signon/' + id, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        isFavourite.value = val

                    } else {
                        func.addLog('projTitleSimple', 'updFavorite', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('projTitleSimple', 'updFavorite - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const updProjName = () => {
            isEdit.value = false

            if (projTitle.value !== newProjName.value) {

                const p = { folderName: newProjName.value }

                axios.put( '/signon/' + projId.value, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        projTitle.value = newProjName.value

                        /* tmpls newProjName.value = computed({
                            get: () => store.getProjectName.value,
                            set: store.updProjectName(newProjName.value),
                        })
                        newProjName.value = store.getProjectName.value  */

                        objLSProj.value = { id: projId.value, name: newProjName.value, status: projStatus.value}
	                    localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj.value))

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Folder name is updated."
                        })

                        emit('refreshProj', projId.value)

                    } else {
                        func.addLog('projTitleSimple', 'updProjName', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('projTitleSimple', 'updProjName - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
            }
        }

        const updProjPrivacy = () => {
            if (privacy.value !== newProjPrivacy.value) {

                const p = { privacy: newProjPrivacy.value }

                axios.put( '/signon/' + projId.value, p)
                    .then((res) => {
                        if (res.data.status === 1) {

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Folder privacy is updated."
                            })
                            emit('refreshProj', projId.value)

                        } else {
                            func.addLog('projTitleSimple', 'updProjPrivacy', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('projTitleSimple', 'updProjPrivacy - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }

        onMounted(() => {
            if (privacy.value !== null && privacy.value !== undefined && privacy.value !== '') {
                newProjPrivacy.value = privacy.value
            }

        })

        return { 
            store, axios, alert, closeAlert, 
            projId, projTitle, projStatus, privacy, isFavourite, isEdit, 
            newProjName, newProjPrivacy, updProjName, updProjPrivacy, updFavorite,
            refreshProj
        }
    }
}
</script>

<style>

</style>